import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SocialIcons from './SocialIcons'
import { ContactAPI } from '../../../API/ClevDocContact'
import { FaSpinner } from 'react-icons/fa'

const Footer = () => {

    const [formData, setFormData] = useState({
        email: ''
    })
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (successMessage || errorMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setErrorMessage('');
            }, 5000); // 5 seconds
            return () => clearTimeout(timer);
        }
    }, [successMessage, errorMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    }

    const handleSubmit = async (e, type) => {
        e.preventDefault();
        

        const validationErrors = {};

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setIsLoading(true);
            try {
                const response = await ContactAPI(formData, type);
                setIsLoading(false);

                if (response.status === 200) {
                    setFormData({
                        email: ''
                    });
                    setSuccessMessage('Subscribed!');
                    setErrorMessage('');
                    
                } else {
                    if (response.errorMessage) {
                        
                        setFormData({
                            email: ''
                        });
                        setErrorMessage(response.errorMessage);
                    } else {
                        console.error('API call failed with status:', response.status);
                        setErrorMessage('An error occurred while processing your request.');
                    }
                }
                
            } catch (error) {
                setFormData({
                    email: ''
                });
                console.error('Error calling API:', error);
            }
        }
    }

    return (
        <>

            <footer className="bg-bgclr5 pb-4 font-avenir">
                <div className="mx-auto w-full max-w-screen-xl">
                    <div className="grid grid-cols-1 gap-2 px-4 py-6 md:pt-8 md:pl-10 md:pr-12 mb-5 md:grid-cols-4">
                        <div>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[7px] decoration-2'>Plat</span>forms</h2>
                            <ul className="text-white font-semibold font-md">
                                <li className="mb-2">
                                    <Link to='/banking' className="hover:underline">Banking</Link>

                                </li>
                                <li className="mb-2">
                                    {/* <Link to='' className="hover:underline"> */}
                                    Legal
                                    {/* </Link> */}
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='' className="hover:underline"> */}
                                    V-Doc
                                    {/* </Link> */}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[7px] decoration-2'>Abo</span>ut</h2>
                            <ul className="text-white font-semibold font-md">
                                <li className="mb-2">
                                    <Link to='/about' className="hover:underline">About ClevDoc</Link>
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='' className="hover:underline"> */}
                                    FAQ
                                    {/* </Link> */}
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='' className="hover:underline"> */}
                                    Team
                                    {/* </Link> */}
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='' className="hover:underline"> */}
                                    Articles
                                    {/* </Link> */}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[7px] decoration-2'>Con</span>tact</h2>
                            <ul className="text-white font-semibold font-md">
                                <li className="mb-2">
                                    <Link to='/careers' className="hover:underline">
                                        Careers
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to='/contact' className="hover:underline">
                                        Contact Us
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    {/* <Link to='' className="hover:underline"> */}
                                    Blogs
                                    {/* </Link> */}
                                </li>
                                <li className="mb-2">
                                    <Link to='/t&c' className="hover:underline">
                                        Term of Use
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link to='/privacy&policy' className="hover:underline">
                                        Privacy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className=''>
                            <h2 className="mb-6 text-xl font-bold text-txtclr4 dark:text-white"><span className='underline underline-offset-[6px] decoration-2'>News</span>Letter</h2>
                            <form onSubmit={(e) => handleSubmit(e, 'subscribe')}>
                                <div className='pb-3'>
                                    {errorMessage && (
                                        <div className="text-red-600 font-semibold mb-1">{errorMessage}</div>
                                    )}
                                    {successMessage && (
                                        <div className="text-green-600 font-semibold mb-1">
                                            {successMessage}
                                        </div>
                                    )}
                                    <input
                                        type="email"
                                        placeholder='Enter Email'
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="input-style"
                                        required
                                    />
                                    <button
                                        type="submit"
                                        className="text-center mt-2 bg-bgclr4 hover:bg-bg-btnhover text-white font-bold py-2 md:px-4 px-4 rounded-md focus:outline-none focus:shadow-outline"
                                    >
                                        {isLoading ? <FaSpinner size={24} className="animate-spin text-3xl" /> : 'Subscribe'}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div className='md:flex justify-between'>
                        <div className='md:flex pl-4 md:pl-10 mb-5 md:mb-0'>
                            <h1 className='text-xl font-bold text-txtclr4 pr-5 pb-2 md:pb-0'>Reach out to Us at</h1>
                            <SocialIcons />
                        </div>
                        <div className='md:flex md:pr-12 pl-4'>
                            <div>
                                <h1 className='text-xl font-bold text-txtclr4 pr-5 pb-2 md:pb-0'>Get the App</h1>
                            </div>
                            <div className='flex gap-3'>
                                <img src="https://cdn.clevdoc.com/assets/footer/app-store-btn2.jpg" className='w-32 rounded' alt="Unavailable" />
                                <img src="https://cdn.clevdoc.com/assets/footer/google-play-btn2.jpg" className='w-32 rounded' alt="unavailable" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className='grid md:grid-cols-2 bg-gray-900 text-gray-200 md:h-16 h-32 items-center'>
                <div className='flex justify-center p-1 md:p-0 border-b-2 md:border-none'>
                    <h1>Copyright © 2024
                        <Link to='/'>
                            <span></span> ClevDoc Platforms Inc. (India) </Link>
                    </h1>
                </div>
                <div className='flex justify-center text-center pb-2'>
                    <div className='mr-5 border-r-2 border-gray-400 pr-5'>
                        <Link to='/privacy&policy'>
                            Privacy Policy
                        </Link>
                    </div>
                    <Link to='/t&c'>
                        Terms & Condition
                    </Link>
                </div>
            </div>

        </>
    )
}

export default Footer