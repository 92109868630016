import { Navigate, Route, Routes } from 'react-router-dom'
import Home from './Components/Landing-Page/Screens/Home'
import Register from './Components/CommonComponents/Register'
import Login from './Components/CommonComponents/Login'
import About from './Components/Landing-Page/Screens/About'
import MainHeader from './Components/Landing-Page/Elements/MainHeader'
import Footer from './Components/Landing-Page/Elements/Footer'
import ContactUs from './Components/Landing-Page/Screens/ContactUs'
import BankingLanding from './Components/Banking-Plateform/Banking-Landing-page/Screens/BankingLanding'
import BankingContact from './Components/Banking-Plateform/Banking-Landing-page/Screens/BankingContact'
import BankingHelp from './Components/Banking-Plateform/Banking-Landing-page/Screens/BankingHelp'
import BankingDashboardHeader from './Components/Banking-Plateform/Banking-Dashboard/Pages/BankingDashboardHeader'
import Header from './Components/CommonComponents/Header'
import BankingDashboardFooter from './Components/Banking-Plateform/Banking-Dashboard/Pages/BankingDashboardFooter'
import IndividualForm from './Components/Banking-Plateform/Banking-Dashboard/Screens/Individual/Form'
import OrganizationForm from './Components/Banking-Plateform/Banking-Dashboard/Screens/Oraganization/Form'
import BankerDashboardHeader from './Components/Banking-Plateform/Banker-Dashboard/Elements/BankerDashboardHeader'
import ForgotPassword from './Components/CommonComponents/ForgotPassword'
import BankLoanSearch from './Components/Banking-Plateform/Banker-Dashboard/Screens/LoanSearch/BankLoanSearch'
import BankCustomerSearch from './Components/Banking-Plateform/Banker-Dashboard/Screens/CustomerSearch/Individual/BankCustomerSearch'
import BorrowerLinkedLoan from './Components/Banking-Plateform/Banking-Dashboard/Screens/LinkedLoans/BorrowerLinkedLoan'
import LinkedLoanDetails from './Components/Banking-Plateform/Banking-Dashboard/Screens/LinkedLoans/LinkedLoanDetails'
import Help from './Components/Banking-Plateform/Banking-Dashboard/Screens/Help/Help'
import BankHelp from './Components/Banking-Plateform/Banker-Dashboard/Screens/BankHelp'
import { createContext, useReducer } from 'react'
import { initialState, reducer } from './Reducer/UserReducer'
import Toast from './CommonFunction/Toast'
import LoanDetail from './Components/Banking-Plateform/Banker-Dashboard/Screens/LoanSearch/LoanDetail'
import PrivateRoute from './Components/CommonComponents/PrivateRoute'
import Profile from './Components/Banking-Plateform/Banking-Dashboard/Screens/Profile/Profile'
import PageNotFound from './Components/CommonComponents/PageNotFound'
import Certificate from './Components/Banking-Plateform/Banking-Dashboard/Screens/POR/Certificate'
import EmailPOD from './Components/Banking-Plateform/Banker-Dashboard/Screens/POD/podFormat'
import WhatsAppVerification from './Components/Banking-Plateform/Banking-Dashboard/Screens/Individual/WhatsAppVerification'
import ServeNoticeSuccess from './Components/Banking-Plateform/Banker-Dashboard/Screens/NoticeService/ServeNoticeSuccess'
import Verification from './Components/Landing-Page/Screens/Document-Verification/Verification'
import Careers from './Components/Landing-Page/Screens/Careers'
import {
  Admin,
  LegalExecutive,
  SalesExecutive,
  SoftwareDeveloper
} from './Components/Landing-Page/Elements/JobDetails'
import OrganizationSearch from './Components/Banking-Plateform/Banker-Dashboard/Screens/CustomerSearch/Organization/OrganizationSearch'
import PrivacyPolicy from './Components/Landing-Page/Screens/PrivacyPolicy'
import TermCondition from './Components/Landing-Page/Screens/TermCondition'
import LoanSearchProfile from './Components/Banking-Plateform/Banker-Dashboard/Elements/LoanSearchProfile'
import Blog from './Components/Landing-Page/Screens/Blog'
import BlogDetails from './Components/Landing-Page/Elements/BlogDetails'
import PurchaseSummary from './Components/Banking-Plateform/Banking-Dashboard/Screens/PaymentGateway/PurchaseSummary'
import PaymentProcess from './Components/Banking-Plateform/Banking-Dashboard/Screens/PaymentGateway/PaymentProcess'
import Inbox from './Components/Banking-Plateform/Banking-Dashboard/Screens/Inbox/Inbox'
import BankServiceNotice from './Components/Banking-Plateform/Banker-Dashboard/Screens/NoticeService/BankServiceNotice'
import BankPOD from './Components/Banking-Plateform/Banker-Dashboard/Screens/POD/BankPOD'
import BankDashboard from './Components/Banking-Plateform/Banker-Dashboard/Screens/Dashboard/BankDashboard'
import VerifyUser from './Components/Landing-Page/Screens/Document-Verification/VerifyUser'
import BorrowerDashboard from './Components/Banking-Plateform/Banking-Dashboard/Screens/Dashboard/BorrowerDashboard'
import ResetPassword from './Components/CommonComponents/ResetPassword'
import { Toaster } from 'react-hot-toast'
import BankerProfile from './Components/Banking-Plateform/Banker-Dashboard/Screens/Profile/BankerProfile'
import BankInbox from './Components/Banking-Plateform/Banker-Dashboard/Screens/Inbox/BankInbox'
import BankOutbox from './Components/Banking-Plateform/Banker-Dashboard/Screens/Outbox/BankOutbox'
import BankAccountSetting from './Components/Banking-Plateform/Banker-Dashboard/Screens/AccountSetting/BankAccountSetting'

export const UserContext = createContext()

const Routings = () => {
  return (
    <>
      <Routes>
        <Route
          path='/*'
          element={
            <>
              {/* <MainHeader /> */}
              <Routes>
                <Route index element={<Home />} />
                <Route path='about' element={<About />} />
                <Route path='contact' element={<ContactUs />} />
                <Route
                  path='careers//*'
                  element={
                    <>
                      <Routes>
                        <Route index element={<Careers />} />
                        <Route
                          path='software-developer'
                          element={<SoftwareDeveloper />}
                        />
                        <Route
                          path='legal-executive'
                          element={<LegalExecutive />}
                        />
                        <Route
                          path='sales-executive'
                          element={<SalesExecutive />}
                        />
                        <Route path='admin' element={<Admin />} />
                      </Routes>
                    </>
                  }
                />
                <Route path='verification' element={<Verification />} />
                <Route path='privacy&policy' element={<PrivacyPolicy />} />
                <Route path='t&c' element={<TermCondition />} />
                <Route path='blogs' element={<Blog />} />
                <Route path='blogsDetails/:id' element={<BlogDetails />} />
                <Route path='verifyUser' element={<VerifyUser />} />
                <Route path='pageNotFound' element={<PageNotFound />} />
                <Route path='/login' element={<Login />} />
                <Route path='/forgotPass' element={<ForgotPassword />} />
                <Route path='/newPassword' element={<ResetPassword />} />
                <Route path='register' element={<Register />} />
              </Routes>
              <Footer />
            </>
          }
        />
        {/* <Route path="*" element={<Navigate to="/pageNotFound" />} /> */}
        <Route
          path='/banking//*'
          element={
            <>
              <MainHeader />
              <Routes>
                <Route index element={<BankingLanding />} />
                <Route path='contactBanking' element={<BankingContact />} />
                <Route path='helpBanking' element={<BankingHelp />} />
                {/* <Route path="*" element={<Navigate to="/pageNotFound" />} /> */}
                <Route
                  path='purchaseSummary'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <PurchaseSummary />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='paymentProcess'
                  element={
                    // <PrivateRoute allowedRoles={["entity", "user"]}>
                    <PaymentProcess />
                    // </PrivateRoute>
                  }
                />
                <Route
                  path='whatsapp/verify'
                  element={<WhatsAppVerification />}
                />
              </Routes>
              <Footer />
            </>
          }
        />
        <Route
          path='/banking/borrowerDashboard//*'
          element={
            <>
              <BankingDashboardHeader />
              <Routes>
                <Route
                  path='1//*'
                  element={
                    <>
                      <Routes>
                        <Route
                          index
                          element={
                            <PrivateRoute allowedRoles={['user']}>
                              <IndividualForm />
                            </PrivateRoute>
                          }
                        />
                      </Routes>
                    </>
                  }
                />
                <Route
                  path='2//*'
                  element={
                    <>
                      <Routes>
                        <Route
                          index
                          element={
                            <PrivateRoute allowedRoles={['entity']}>
                              <OrganizationForm />
                            </PrivateRoute>
                          }
                        />
                      </Routes>
                    </>
                  }
                />
                <Route
                  path='dashboard'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <BorrowerDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='profile'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='certificate'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <Certificate />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='inbox'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <Inbox />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='dashboard'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <BankDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='linkedLoans'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <BorrowerLinkedLoan />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='linkedLoanDetails'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <LinkedLoanDetails />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="setting"
                  element={
                    <PrivateRoute allowedRoles={["entity", "user"]}>
                      <AccountSetting />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path='help'
                  element={
                    <PrivateRoute allowedRoles={['entity', 'user']}>
                      <Help />
                    </PrivateRoute>
                  }
                />
              </Routes>
              <BankingDashboardFooter />
            </>
          }
        />
        <Route
          path='/banking/bankDashboard//*'
          element={
            <>
              <BankerDashboardHeader />
              <div className='p-4'>
                <Routes>
                  <Route
                    index
                    element={
                      <PrivateRoute allowedRoles={['admin']}>
                        <BankDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='profile'
                    element={
                      <PrivateRoute allowedRoles={['admin']}>
                        <BankerProfile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='inbox'
                    element={
                      <PrivateRoute allowedRoles={['admin']}>
                        <BankInbox />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='outbox'
                    element={
                      <PrivateRoute allowedRoles={['admin']}>
                        <BankOutbox />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='loanSearch//*'
                    element={
                      <>
                        <Routes>
                          <Route
                            index
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <BankLoanSearch />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='ucidetail/:uci'
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <LoanSearchProfile />
                              </PrivateRoute>
                            }
                          />
                        </Routes>
                      </>
                    }
                  />
                  <Route
                    path='searchIndividual//*'
                    element={
                      <>
                        <Routes>
                          <Route
                            index
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <BankCustomerSearch />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='loanDetail/:loanId'
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <LoanDetail />
                              </PrivateRoute>
                            }
                          />
                        </Routes>
                      </>
                    }
                  />
                  <Route
                    path='searchOrganization//*'
                    element={
                      <>
                        <Routes>
                          <Route
                            index
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <OrganizationSearch />
                              </PrivateRoute>
                            }
                          />

                          <Route
                            path='loanDetail/:loanId'
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <LoanDetail />
                              </PrivateRoute>
                            }
                          />
                        </Routes>
                      </>
                    }
                  />
                  <Route
                    path='POD//*'
                    element={
                      <>
                        <Routes>
                          <Route
                            index
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <BankPOD />
                              </PrivateRoute>
                            }
                          />
                          <Route path='podFormat' element={<EmailPOD />} />
                        </Routes>
                      </>
                    }
                  ></Route>
                  <Route
                    path='accountSetting'
                    element={
                      <PrivateRoute allowedRoles={['admin']}>
                        <BankAccountSetting />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path='serviceNotice//*'
                    element={
                      <>
                        <Routes>
                          <Route
                            index
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <BankServiceNotice />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='serveNoticeSuccess'
                            element={
                              <PrivateRoute allowedRoles={['admin']}>
                                <ServeNoticeSuccess />
                              </PrivateRoute>
                            }
                          />
                        </Routes>
                      </>
                    }
                  />
                  <Route
                    path='help'
                    element={
                      <PrivateRoute allowedRoles={['admin']}>
                        <BankHelp />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </div>
              <Toast />
            </>
          }
        />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </>
  )
}

function App () {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <>
      <UserContext.Provider value={{ state, dispatch }}>
        <div className='bg-bgclr8 overflow-hidden'>
          <Header />
          <Routings />
        </div>
      </UserContext.Provider>
      {/* <Copyright /> */}
      <Toast />
      <Toaster
        position='top-right'
        reverseOrder={false}
        gutter={8}
        containerClassName=''
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            marginTop: '70px'
          },
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black'
            }
          }
        }}
      />
    </>
  )
}

export default App
