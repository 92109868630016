import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from '../../App'
import cookies from '../../CommonFunction/cookies'
import {
  fetchIndividualData,
  fetchOrganizationData
} from '../../API/ClevDocContact'
import { showFailed } from '../../CommonFunction/SweetAlert'
import { MdEmail } from 'react-icons/md'
import toast from 'react-hot-toast'
// import Loader from "../../CommonFunction/Loader";

const Login = () => {
  const { state, dispatch } = useContext(UserContext)
  const [formData, setFormData] = useState({
    email: '',
    role: 'user',
    type: 'login'
  })

  // const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [otp, setOtp] = useState('')
  const [timer, setTimer] = useState(10)
  const [showResend, setShowResend] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingVerify, setIsLoadingVerify] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bankLoading, setBankLoading] = useState(false)
  const [timerMsg, setTimerMsg] = useState(false)
  const [errorOTP, setErrorOTP] = useState('')
  const [emailError, setEmailError] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const { email, role } = location.state || {}

  const [bankerData, setBankerData] = useState({
    userID: '',
    password: ''
  })

  const [chooseRole, setChooseRole] = useState('borrower')

  useEffect(() => {
    console.log('first--', email, role)
    if (role && email) {
      setFormData({ ...formData, role: role, email: email })
    }
    return () => {
      setFormData({
        role: 'user',
        type: 'login'
      })
    }
  }, [])

  const handleRoleChange = event => {
    setChooseRole(event.target.value)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setEmailError('')
  }

  const handleBankChange = e => {
    const { name, value } = e.target
    setBankerData(prevData => ({
      ...prevData,
      [name]: name === 'userID' ? value.toUpperCase() : value
    }))
  }

  const handleOptionChange = event => {
    setFormData({ ...formData, role: event.target.value })
  }

  const CountDown = () => {
    // Reset timer
    setTimer(60) // Reset timer to 10 seconds
    setShowResend(false)

    // Start timer countdown
    const interval = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 0) {
          clearInterval(interval)
          setShowResend(true)
          return 0
        }
        return prevTimer - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }

  // Borrower Login
  const getOTP = async e => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    if (!formData.email) {
      setEmailError('Email is required!')
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      setEmailError('Email is invalid')
    } else {
      setLoading(true)
      let dataToSend = {}
      try {
        if (formData.role === 'user') {
          dataToSend = {
            email: formData.email,
            role: formData.role
          }
        } else if (formData.role === 'entity') {
          dataToSend = {
            email: formData.email,
            role: 'entity'
          }
        }

        if (chooseRole === 'borrower') {
          setIsLoading(true)
          const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/auth/login`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(dataToSend)
            }
          )
          const result = await response.json()

          if (response.ok && result.code === 200) {
            setLoading(false)
            setTimerMsg(true)
            toast.success(`${result.message}`)
            CountDown()
          } else {
            console.error('Login failed:', result.error[0])
            setLoading(false)
            setIsLoading(false)
            showFailed('Failed.', `${result.error[0]}`)
            navigate('/register', { state: { role: formData.role, email: formData?.email} })
          }
        }
      } catch (error) {
        showFailed('Failed.', `${error}`)
        console.error('Login error:', error)
      }
    }
  }

  // Bank Login
  const bankLogin = async e => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    setBankLoading(true)
    try {
      if (chooseRole === 'bank') {
        const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/auth/bank/login`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              system_id: bankerData.userID,
              password: bankerData.password,
              role: 'admin'
            })
          }
        )
        const result = await response.json()

        if (response.ok) {
          cookies.set('jwt', result.accessToken, {
            path: '/',
            Domain: 'https://clevdoc.com',
            secure: true,
            sameSite: 'None'
          })
          setBankLoading(false)
          toast.success(`You Are Successfully Login.`)
          dispatch({ type: 'USER', payload: true })
          navigate('/banking/bankDashboard')
        } else {
          setBankLoading(false)
          showFailed('Failed.', `${result.error[0]}`)
          console.error('Login failed:', response.statusText)
        }
      }
    } catch (error) {
      setBankLoading(false)
      showFailed('Failed.', `${error}`)
      console.error('Login error:', error)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (chooseRole === 'borrower') {
      await otpVerify(e)
    } else if (chooseRole === 'bank') {
      await bankLogin(e)
    }
  }

  const fetchDataAndUpdateUserData = async jwtToken => {
    try {
      const data = await fetchIndividualData(jwtToken)
      if (data?.step === 2) {
        navigate('/banking/borrowerDashboard/inbox')
      } else if (data?.step === 1) {
        navigate('/banking/borrowerDashboard/1')
      }
    } catch (error) {
      console.error('Error retrieving user data after otp:', error)
    }
  }

  const fetchOrgDataAndUpdate = async jwtToken => {
    try {
      const data = await fetchOrganizationData(jwtToken)
      if (data?.step === 2) {
        navigate('/banking/borrowerDashboard/inbox')
      } else if (data?.step === 1) {
        navigate('/banking/borrowerDashboard/2')
      }
    } catch (error) {
      console.error('Error retrieving organization data:', error)
    }
  }

  // OTP verify
  const otpVerify = async e => {
    e.preventDefault()
    if (!otp) {
      setErrorOTP('Verification code Required')
    } else if (otp.length < 5) {
      setErrorOTP('OTP should be 5 number')
    } else {
      setIsLoadingVerify(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/auth/verify`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              code: otp,
              email: formData.email,
              role: formData.role,
              type: formData.type
            })
          }
        )

        const result = await response.json()

        if (response.status === 200 && formData.role === 'user') {
          cookies.set('jwt', result.accessToken, {
            path: '/',
            Domain: 'https://clevdoc.com',
            secure: true,
            sameSite: 'None'
          })
          const jwtToken = cookies.get('jwt')
          await fetchDataAndUpdateUserData(jwtToken)
          toast.success(`You Are Successfully Login.`)
          dispatch({ type: 'USER', payload: true })
          navigate('/banking/borrowerDashboard/1')
          setIsLoadingVerify(false)
        } else if (response.status === 200 && formData.role === 'entity') {
          cookies.set('jwt', result.accessToken, {
            path: '/',
            Domain: 'https://clevdoc.com',
            secure: true,
            sameSite: 'None'
          })
          const jwtToken = cookies.get('jwt')
          await fetchOrgDataAndUpdate(jwtToken)
          toast.success(`You Are Successfully Login.`)
          dispatch({ type: 'USER', payload: true })
          navigate('/banking/borrowerDashboard/2')
          setIsLoadingVerify(false)
        } else {
          showFailed('Failed.', `${result.error[0]}`)
          setOtp('')
          console.error('OTP verification failed:', result.error)
          setIsLoadingVerify(false)
        }
      } catch (error) {
        showFailed('Failed.', `${error}`)
        console.error('Error during OTP verification:', error)
        setIsLoadingVerify(false)
      }
    }
  }

  return (
    <>
      <div className='container mx-auto pt-36 mb-20 font-avenir '>
        <div className='md:py-5 rounded-3xl'>
          <div className='md:grid md:grid-cols-2 lg:py-5 rounded-3xl'>
            <div className='md:block hidden text-center mt-7'>
              <h1 className='text-3xl font-semibold text-bgclr'>
                Streamlined Digital Communications,
              </h1>
              <h1 className='text-3xl font-semibold text-bgclr'>
                Seamless Compliance
              </h1>
              <p className='mt-5'>
                Efficient Financial Communications, Simplified Processes
              </p>
              <div className='flex justify-center mt-5'>
                <img
                  src='https://cdn.clevdoc.com/assets/Login/login-img.png'
                  alt=''
                />
              </div>
            </div>
            <div className='md:mx-10 mx-3'>
              <h1 className='text-3xl font-semibold text-center md:text-left text-bgclr mb-4'>
                Welcome!
              </h1>
              <h1 className='text-lg font-bold text-bgclr mb-2'>Login</h1>
              <form onSubmit={e => handleSubmit(e)}>
                <div className='mb-5 p-4 bg-[#E8ECF0] font-semibold rounded-md text-txtclr2'>
                  <label className='block text-sm font-medium  mb-1'>
                    Select Role<span className='text-red-600'>*</span>
                  </label>
                  <div className=''>
                    <select
                      className='block w-full p-2 text-sm text-gray-500 border border-gray-300 rounded-md shadow-sm'
                      onChange={handleRoleChange}
                    >
                      <option value='borrower' selected>
                        Borrower
                      </option>
                      <option value='bank'>Bank</option>
                    </select>
                  </div>
                </div>
                {chooseRole === 'borrower' && (
                  <div>
                    <div className='mb-3 text-txtclr3 font-semibold'>
                      <label className='block text-sm font-medium text-txtclr2 mb-1'>
                        Borrower Type<span className='text-red-600'>*</span>
                      </label>
                      <div>
                        <label className='mr-5'>
                          <input
                            type='radio'
                            value='user'
                            checked={formData.role === 'user'}
                            onChange={handleOptionChange}
                            className='accent-txtclr3 mr-1'
                          />
                          Individual
                        </label>

                        <label>
                          <input
                            type='radio'
                            value='entity'
                            checked={formData.role === 'entity'}
                            onChange={handleOptionChange}
                            className='accent-txtclr3 mr-1'
                          />
                          Organization
                        </label>
                      </div>
                    </div>
                    <div className='grid grid-cols-9 mt-5 gap-2'>
                      {/* Email ID */}
                      <div className='col-span-7'>
                        <label
                          htmlFor='email'
                          className='block text-sm font-medium text-txtclr2 mb-1'
                        >
                          Email ID<span className='text-red-600'>*</span>
                        </label>
                        <input
                          type='email'
                          id='email'
                          name='email'
                          value={formData.email}
                          onChange={handleChange}
                          className='pl-10 input-style'
                          placeholder='Email ID'
                          disabled={isLoading}
                        />
                        <MdEmail
                          className='absolute -mt-7 ml-4 text-gray-400
                    flex items-center  
                    pointer-events-none'
                        />
                      </div>

                      <div className='flex justify-center col-span-2 mt-6'>
                        <button
                          onClick={getOTP}
                          className='w-full text-center bg-bgclr hover:bg-bgclr6 text-txtclr font-semibold rounded-md focus:outline-none focus:shadow-outline'
                          disabled={isLoading}
                        >
                          {loading ? 'Sending' : 'Get OTP'}
                        </button>
                      </div>
                    </div>
                    <div className='grid grid-cols-2 h-9'>
                      <div>
                        {emailError && (
                          <span className='text-red-600 font-semibold'>
                            {emailError}
                          </span>
                        )}
                      </div>

                      {timerMsg && (
                        <div className='flex justify-end lg:mr-24'>
                          {timer > 0 && (
                            <div className='text-gray-700 italic'>
                              Resend OTP in{' '}
                              <span className='text-green-800 font-semibold'>
                                {' '}
                                {Math.floor(timer / 60)}:
                                {timer % 60 < 10
                                  ? `0${timer % 60}`
                                  : timer % 60}
                              </span>
                            </div>
                          )}
                          {showResend && (
                            <div>
                              <button
                                className='ml-2 text-nowrap lg:mr-2 mr-20 text-txtclr3 underline italic'
                                onClick={e => getOTP(e)}
                              >
                                Resend OTP
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor='email'
                        className='block text-sm font-medium text-txtclr2 mb-1'
                      >
                        Verification Code<span className='text-red-600'>*</span>
                      </label>
                      <input
                        type='password'
                        id='otp'
                        name='otp'
                        maxLength='5'
                        value={otp}
                        onInput={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                        }}
                        onChange={e => {
                          setOtp(e.target.value)
                          setErrorOTP('')
                        }}
                        className='input-style'
                        placeholder='Enter your verification Code'
                        disabled={!timerMsg}
                      />
                      {errorOTP && (
                        <span className='text-red-600 font-semibold'>
                          {errorOTP}
                        </span>
                      )}
                    </div>

                    <div className='text-center mt-2'>
                      <button
                        type='submit'
                        className='mt-5 bg-bgclr w-full hover:bg-bg-btnhover text-txtclr px-4 py-2 rounded'
                        disabled={!timerMsg || isLoadingVerify}
                      >
                        {isLoadingVerify ? 'Logging in...' : 'Log In'}
                      </button>
                    </div>
                    <div className='text-center pt-5 hover:underline'>
                      <Link to='/register'>
                        Don't have an account? Register
                      </Link>
                    </div>
                  </div>
                )}

                {chooseRole === 'bank' && (
                  <div>
                    <div className='mb-4'>
                      <label
                        htmlFor='userID'
                        className='block text-sm font-medium text-txtclr2 mb-1'
                      >
                        User ID<span className='text-red-600'>*</span>
                      </label>
                      <input
                        type='text'
                        id='userID'
                        name='userID'
                        value={bankerData.userID}
                        onChange={handleBankChange}
                        className='input-style'
                        placeholder='Enter your User ID'
                        required
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        htmlFor='password'
                        className='block text-sm font-medium text-txtclr2 mb-1'
                      >
                        Password<span className='text-red-600'>*</span>
                      </label>
                      <input
                        type='password'
                        id='password'
                        name='password'
                        onChange={handleBankChange}
                        value={bankerData.password}
                        className='input-style'
                        placeholder='Enter your Password'
                        required
                      />
                    </div>
                    <div className='text-center mt-5'>
                      <button
                        type='submit'
                        className='mt-2 w-full bg-bgclr hover:bg-bgclr6 text-txtclr px-4 py-2 rounded'
                        disabled={bankLoading}
                      >
                        {bankLoading ? 'Logging in...' : 'Log In'}
                      </button>
                    </div>
                    <div className='text-center text-gray-500 mt-6 hover:underline'>
                      <Link to='/forgotPass'>Forgot Password ?</Link>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
