import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cookies from '../../../../../CommonFunction/cookies'
import { usePagination, useTable } from 'react-table'
import { useRef } from 'react'
import DataTable from '../../Elements/DataTable'
import EmptyDataAlert from '../../Elements/EmptyDataAlert'
import Pagination from '../../Elements/Pagination'
import ChooseNotice from './ChooseNotice'
import { IoCheckmarkOutline } from 'react-icons/io5'
import { GetEnvId, NoticeStatus } from '../../../../../API/Bank/NoticeServe'
import TitleCaseConverter from '../../../../../CommonFunction/TitleCaseConverter'
import { NoticeLoading } from '../../Elements/ServeLoading'
import { FaSpinner } from 'react-icons/fa'
import Loader from '../../../../../CommonFunction/Loader'
import { fetchLoanList } from '../../../../../API/Bank/LoanSearch'

const BankServiceNotice = () => {
  const [showDetails, setShowDetails] = useState(false)
  const jwtToken = cookies.get('jwt')
  const [loanData, setLoanData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [userLoanData, setUserLoanData] = useState({})
  const [selectedLoanId, setSelectedLoanId] = useState(null)
  const [openSelector, setOpenSelector] = useState(false)
  const [noticeName, setNoticeName] = useState('')
  const [selectedNotice, setSelectedNotice] = useState('')
  const [noticeType, setNoticeType] = useState('');
  const [envId, setEnvId] = useState([])
  const [buttonLoading, setButtonLoading] = useState({})
  const [anyButtonLoading, setAnyButtonLoading] = useState(false)
  const sectionRef = useRef(null)

  const [pagination, setPagination] = useState({
    totalDocs: 0,
    totalPages: 1,
    page: 0,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false
  })

  // Fetch Loan List with Pagination
  const fetchData = useCallback(
    async (query = '', page = 1) => {
      setIsLoading(true)
      try {
        const data = await fetchLoanList(jwtToken, page, query)
        setLoanData(data?.docs || [])
        setPagination({
          totalDocs: data.totalDocs,
          totalPages: data.totalPages,
          page: data.page,
          limit: data.limit,
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [jwtToken]
  )

  const fetchLoanData = useCallback(
    async loanId => {
      try {
        const data = await NoticeStatus(jwtToken, loanId)
        setUserLoanData(data)
        if (data.sarfesi_notices?.some(notice => notice.status === 'sent')) {
          setSelectedNotice('sarfesi_notices')
        } else if (
          data.arbitration_notices?.some(notice => notice.status === 'sent')
        ) {
          setSelectedNotice('arbitration_notices')
        }
      } catch (error) {
        console.error('Failed to fetch loan data:', error)
      }
    },
    [jwtToken]
  )

  // Handle Loan Selection
  const handleLoanSelection = useCallback(
    async id => {
      setNoticeType('');
      setSelectedNotice('');
      setSelectedLoanId(prevId => (prevId === id ? null : id))
      if (id !== selectedLoanId) {
        await fetchLoanData(id)
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    },
    [fetchLoanData, selectedLoanId]
  )

  // Handle Notice Selection
  const handleOptionChoose = useCallback(
    async (value, type) => {
      setAnyButtonLoading(true)
      setButtonLoading(prev => ({ ...prev, [type]: true }))

      try {
        const envPayload = { loan_id: selectedLoanId, type: value }
        const envData = await GetEnvId(jwtToken, envPayload)
        setEnvId(envData)
        setNoticeName(value)
        setOpenSelector(true)
      } catch (error) {
        console.error('Error fetching envelope ID:', error)
      } finally {
        setButtonLoading(prev => ({ ...prev, [type]: false }))
        setAnyButtonLoading(false)
      }
    },
    [jwtToken, selectedLoanId]
  )

  // Pagination Handlers
  const nextPage = useCallback(() => {
    if (pagination.hasNextPage) fetchData(pagination.page + 1, searchQuery)
  }, [fetchData, searchQuery, pagination])

  const previousPage = useCallback(() => {
    if (pagination.hasPrevPage) fetchData(pagination.page - 1, searchQuery)
  }, [fetchData, searchQuery, pagination])

  const noticesToDisplay = useMemo(
    () => userLoanData[noticeType || selectedNotice] || [],
    [userLoanData, noticeType, selectedNotice]
  )

  const handleSearch = async () => {
    fetchData(searchQuery)
    setShowDetails(true)
  }

  const handleRadioChange = event => {
    if(event.target.value){
      console.log("firstjbhfvb------",event.target.value);
      setNoticeType(event.target.value)
    }
  }

  const onClose = () => {
    setOpenSelector(false)
    setNoticeName(null)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Select',
        accessor: '_id',
        Cell: ({ row }) => (
          <div className='text-center'>
            <input
              type='checkbox'
              onChange={() => handleLoanSelection(row.original._id)}
              checked={selectedLoanId === row.original._id}
            />
          </div>
        )
      },
      {
        Header: 'Loan Number',
        accessor: 'identity_number',
        Cell: ({ value }) => <div className='text-center'>{value}</div>
      },
      {
        Header: 'Borrower Name',
        accessor: 'positions',
        Cell: ({ row }) => (
          <div className='text-left'>
            {row.original.positions?.map((pos, index) => {
              const entity = pos.user || pos.organization
              const name = entity?.first_name
                ? `${entity.first_name} ${entity.last_name}`
                : entity?.name
              return <div key={index}>{name || 'N/A'}</div>
            })}
          </div>
        )
      },
      {
        Header: 'Borrower Type',
        accessor: 'borrowerType',
        Cell: ({ row }) => (
          <div className='text-center'>
            {row.original.positions?.map((pos, index) => (
              <div key={index}>
                {pos.user
                  ? 'Individual'
                  : pos.organization
                  ? 'Organization'
                  : 'Unknown'}
              </div>
            ))}
          </div>
        )
      },
      {
        Header: 'Loan Amount',
        accessor: 'amount',
        Cell: ({ value }) => <div className='text-center'>{value}</div>
      }
    ],
    [selectedLoanId, handleLoanSelection]
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data: loanData,
        initialState: { pageIndex: 0 },
        pageCount: pagination.totalPages,
        manualPagination: true
      },
      usePagination
    )

  return (
    <>
      <div className='root-style'>
        <div className='text-[24px] font-[600] my-5 text-bgclr6'>
          <h1>Serve Notice / Reminder</h1>
        </div>
        <div className='searchBar-style'>
          <input
            type='search'
            placeholder='Enter Name, Aadhaar Number, PAN Number or Loan Number........'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className='border border-[#EBEBEB] rounded-md w-full pl-6 pr-5 py-3'
          />
          <button
            onClick={() => handleSearch()}
            className='bg-btnclr rounded-md px-6 font-[500] text-txtclr'
          >
            Search
          </button>
        </div>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {loanData.length === 0 ? (
              <EmptyDataAlert title='Search user for Notice Serve' />
            ) : (
              <div>
                <div className='mt-6'>
                  <div className='pt-3 overflow-x-auto'>
                    <DataTable
                      getTableProps={getTableProps}
                      headerGroups={headerGroups}
                      getTableBodyProps={getTableBodyProps}
                      page={page}
                      prepareRow={prepareRow}
                      selectedLoanId={selectedLoanId}
                    />
                  </div>
                  <Pagination
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={pagination.hasPrevPage}
                    canNextPage={pagination.hasNextPage}
                    pagination={{
                      page: pagination.page,
                      totalPages: pagination.totalPages
                    }}
                  />
                </div>

                {selectedLoanId && (
                  <div
                    ref={sectionRef}
                    className='bg-bgclr9 pb-6 pt-10 px-5 rounded-3xl'
                  >
                    <div className='md:text-[24px] text-[20px] font-[600] text-bgclr6'>
                      <h1>Choose Document to Serve :-</h1>
                    </div>
                    <div className='md:ml-10 ml-3 mt-5'>
                      <ol className={`relative text-gray-500`}>
                        {userLoanData?.noticeTypes?.map((step, index) => (
                          <div>
                            <li key={index} className={`ml-8 text-bgclr mb-10`}>
                              <span
                                className={`absolute text-txtclr flex items-center justify-center w-[44px] h-[44px] rounded-full -left-4 bg-bgclr`}
                              >
                                <svg
                                  className='w-3.5 h-3.5'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 16 12'
                                ></svg>
                                <div className='absolute'>{step?.order}</div>
                              </span>
                              <div className='flex justify-between'>
                                <h3 className='font-[400] text-[16px] md:w-[359px] w-[259px] flex items-center leading-tight h-[40px] md:pl-3 pl-2'>
                                  {step.title}
                                </h3>
                                <button
                                  onClick={() =>
                                    handleOptionChoose(step.type, step.type)
                                  }
                                  className={`w-[105px] h-[44px] flex justify-center items-center rounded-md 
                                ${
                                  step?.status === 'inactive'
                                    ? 'bg-gray-300'
                                    : ''
                                } 
                                ${
                                  step?.status === 'sent' ? 'bg-[#2ed573]' : ''
                                } 
                                ${step?.status === 'active' ? 'bg-bgclr' : ''}
                                ${
                                  step?.status === 'closed' ? 'bg-gray-300' : ''
                                }  text-txtclr`}
                                  disabled={
                                    step?.status === 'inactive' ||
                                    step?.status === 'sent' ||
                                    step?.status === 'closed' ||
                                    anyButtonLoading
                                  }
                                >
                                  {step?.status === 'inactive'
                                    ? 'Inactive'
                                    : ''}
                                  {step?.status === 'sent' ? (
                                    <IoCheckmarkOutline size={30} />
                                  ) : (
                                    ''
                                  )}
                                  {buttonLoading[step.type] ? (
                                    <FaSpinner className='animate-spin text-3xl' />
                                  ) : (
                                    `${
                                      step?.status === 'active' ? 'Serve' : ''
                                    }`
                                  )}
                                  {step?.status === 'closed' ? 'Closed' : ''}
                                </button>
                              </div>
                            </li>
                            {/* <div className={`h-[50px] w-[3px] ml-1 text-txtclr flex items-center justify-center bg-[#BABABA]`}>
                          </div> */}
                          </div>
                        ))}
                      </ol>
                    </div>
                    <div>
                      <div className='text-[24px] font-[600] text-bgclr6'>
                        <h1>Choose Applicable Legal Act :-</h1>
                      </div>
                      <div className='flex items-center mt-2'>
                        <input
                          type='radio'
                          id='sarfaesi'
                          name='notice'
                          value='sarfesi_notices'
                          checked={noticeType === 'sarfesi_notices' || selectedNotice === 'sarfesi_notices'}
                          onChange={handleRadioChange}
                          className='ml-2'
                          disabled={selectedNotice === 'arbitration_notices'}
                        />
                        <label htmlFor='sarfaesi' className='text-[16px] ml-4'>
                          Sarfaesi Act
                        </label>

                        <input
                          type='radio'
                          id='arbitration'
                          name='notice'
                          value='arbitration_notices'
                          checked={noticeType === 'arbitration_notices' || selectedNotice === 'arbitration_notices'}
                          onChange={handleRadioChange}
                          className='ml-10'
                          disabled={selectedNotice === 'sarfesi_notices'}
                        />
                        <label
                          htmlFor='arbitration'
                          className='text-[16px] ml-4'
                        >
                          Arbitration Act
                        </label>
                      </div>
                    </div>
                    {noticeType && (
                      <div className='text-[24px] font-[600] text-bgclr6 mt-10'>
                        <h1>
                          <TitleCaseConverter inputString={noticeType} /> :-
                        </h1>
                      </div>
                    )}
                    {selectedNotice && (
                      <div className='text-[24px] font-[600] text-bgclr6 mt-10'>
                        <h1>
                          <TitleCaseConverter inputString={selectedNotice} /> :-
                        </h1>
                      </div>
                    )}
                    <div className='md:ml-10 ml-3 mt-5'>
                      <ol className={`relative text-gray-500`}>
                        {noticesToDisplay?.map((step, index) => (
                          <div>
                            <li key={index} className={`ml-8 text-bgclr mb-10`}>
                              <span
                                className={`absolute text-txtclr flex items-center justify-center w-[44px] h-[44px] rounded-full -left-4 bg-bgclr`}
                              >
                                <svg
                                  className='w-3.5 h-3.5'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 16 12'
                                ></svg>
                                <div className='absolute'>{step?.order}</div>
                              </span>
                              <div className='flex justify-between'>
                                <h3 className='font-[400] text-[16px] md:w-[359px] w-[259px] flex items-center leading-tight h-[40px] pl-3'>
                                  {step.title}
                                </h3>
                                <button
                                  onClick={() =>
                                    handleOptionChoose(step.type, step.type)
                                  }
                                  className={`w-[105px] h-[44px] flex justify-center items-center rounded-md 
                              ${
                                step?.status === 'inactive' ? 'bg-gray-300' : ''
                              } 
                              ${step?.status === 'sent' ? 'bg-[#2ed573]' : ''} 
                              ${step?.status === 'active' ? 'bg-bgclr' : ''}
                              ${
                                step?.status === 'closed' ? 'bg-gray-300' : ''
                              } text-txtclr`}
                                  disabled={
                                    step?.status === 'inactive' ||
                                    step?.status === 'sent' ||
                                    anyButtonLoading
                                  }
                                >
                                  {step?.status === 'inactive'
                                    ? 'Inactive'
                                    : ''}
                                  {step?.status === 'sent' ? (
                                    <IoCheckmarkOutline size={30} />
                                  ) : (
                                    ''
                                  )}
                                  {buttonLoading[step.type] ? (
                                    <FaSpinner className='animate-spin text-3xl' />
                                  ) : (
                                    `${
                                      step?.status === 'active' ? 'Serve' : ''
                                    }`
                                  )}
                                  {step?.status === 'closed' ? 'Closed' : ''}
                                </button>
                              </div>
                            </li>
                          </div>
                        ))}
                      </ol>
                    </div>
                  </div>
                )}
                {openSelector && (
                  <ChooseNotice
                    onClose={onClose}
                    noticeStatus={userLoanData}
                    envId={envId}
                    selectedLoanId={selectedLoanId}
                    isOpen={openSelector}
                    noticeName={noticeName}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default BankServiceNotice
