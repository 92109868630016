import React from 'react'
import { GrContact } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { FaUserGraduate } from "react-icons/fa";
import { MdOutlineArticle } from 'react-icons/md';
import Buttons from '../../../CommonFunction/Buttons';

const Cards = () => {

    const cardData = [
        {
            icon: <MdOutlineArticle size={35} />,
            heading: 'Articles',
            detail: "Explore insightful articles in our dedicated section for diverse knowledge and perspectives..",
            button: <Link to='/'>
                <Buttons.Button2 label="Explore" />
            </Link>

        },
        {
            icon: <FaUserGraduate size={35} />,
            heading: 'Careers',
            detail: "Explore impactful career opportunities with ClevDoc where innovation meets empowerment.",
            button: <Link to='/careers'>
                <Buttons.Button2 label="Apply" />
            </Link>
        },
        {
            icon: <GrContact size={35} />,
            heading: 'Contact',
            detail: "Explore platforms for streamlined processes, enhanced productivity in your organization.",
            button: <Link to='/contact'>
                <Buttons.Button2 label="Message" />
            </Link>
        },


    ]

    return (
        <>
            <div className='bg-bgclr'>
                <div className='md:container md:mx-auto'>
                    <div className='md:flex md:px-5 py-20 gap-6 mx-5 justify-center'>
                        {cardData.map((data, index) => (
                            <div className="md:w-80 px-3 pt-4 h-60 mb-5 justify-center bg-gray-100 border border-gray-200 rounded-lg shadow ">
                                <div className='flex justify-center text-txtclr3'>
                                    {data.icon} 
                                </div>
                                <div className='flex justify-center'>
                                    <h5 className="text-2xl font-semibold tracking-tight text-txtclr3">{data.heading}</h5>
                                </div>
                                <div className='flex'>
                                    <p className="font-normal text-center text-gray-500">{data.detail}</p>
                                </div>
                                <div className="flex mt-2 justify-center ">
                                    {data.button}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards