import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cookies from '../../../../../CommonFunction/cookies'
import {
  fetchBankDetails,
  updateBankDetails
} from '../../../../../API/Bank/BankDetails'
import { showFailed } from '../../../../../CommonFunction/SweetAlert'
import Loader from '../../../../../CommonFunction/Loader'
import { FaSpinner } from 'react-icons/fa'

const BankerProfile = () => {
  const [formData, setFormData] = useState({
    bankName: '',
    branchName: '',
    branchID: '',
    location: '',
    IFSCCode: '',
    branchContact: '',
    managerName: '',
    managerPhone: '',
    managerEmpId: '',
    managerEmpEmail: ''
  })

  const [isEditable, setIsEditable] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [emailErr, setEmailErr] = useState('')
  const [nameErr, setNameErr] = useState('')
  const [phoneErr, setPhoneErr] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  const jwtToken = cookies.get('jwt')

  const fetchData = async () => {
    try {
      const data = await fetchBankDetails(jwtToken)
      setFormData({
        bankName: data?.bank?.name || '',
        branchName: data?.name || '',
        branchID: data?.b_id || '',
        location: data?.address?.address || '',
        IFSCCode: data?.ifsc || '',
        branchContact: data?.phone_number || '',
        managerName: data?.manager?.name || '',
        managerPhone: data?.manager?.phone_number || '',
        managerEmpId: data?.manager?.employee_id || '',
        managerEmpEmail: data?.manager?.email || ''
      })
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let hasError = false

    if (!formData?.managerEmpEmail) {
      setEmailErr('Email is required')
      hasError = true
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        formData?.managerEmpEmail
      )
    ) {
      setEmailErr('Email is invalid')
      hasError = true
    } else {
      setEmailErr('')
    }

    if (!formData?.managerPhone) {
      setPhoneErr('Mobile Number is required')
      hasError = true
    } else if (!/^\d{10}$/.test(formData?.managerPhone)) {
      setPhoneErr('Mobile number should be 10 digits')
      hasError = true
    } else {
      setPhoneErr('')
    }

    if (!formData?.managerName) {
      setNameErr('Name is required')
      hasError = true
    } else if (formData?.managerName?.length < 3) {
      setNameErr('Name should be at least 3 characters.')
      hasError = true
    } else {
      setNameErr('')
    }

    if (hasError) {
      return
    }

    const dataToSend = {
      email: formData?.managerEmpEmail,
      name: formData?.managerName,
      employee_id: formData?.managerEmpId,
      phone_number: formData?.managerPhone
    }
    setIsUpdate(true)
    try {
      await updateBankDetails(dataToSend, jwtToken)
      setIsEditable(false)
      fetchData()
      setIsUpdate(false)
    } catch (error) {
      setIsUpdate(false)
      showFailed('Failed.', `${error}`)
    }
  }

  const toggleEdit = () => {
    setIsEditable(!isEditable)
  }

  return (
    <>
      <div className='root-style'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='bg-txtclr rounded-md md:p-8 p-5'>
            <div className='md:gap-4'>
              <div className='flex justify-between'>
                <div>
                  <h1 className='font-bold text-xl text-bgclr'>
                    BASIC DETAILS
                  </h1>
                </div>
              </div>

              <div className='flex flex-col lg:flex-row gap-6 mt-2'>
                <div className='bg-bgclr9 lg:w-[50%] px-5 py-3 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
                  <div className='text-[18px] leading-10'>
                    <table className='w-full'>
                      <tr className='flex items-start mb-3 leading-7'>
                        <td className='text-gray-500 w-[42%]'>Bank Name: </td>
                        <td className='text-[#000000] '>
                          {formData?.bankName}
                        </td>
                      </tr>
                      <tr className='flex items-start mb-3 leading-7'>
                        <td className='text-gray-500 w-[42%]'>Branch Name: </td>
                        <td>
                          {formData?.branchName}
                        </td>
                      </tr>
                      <tr className='flex items-start mb-3 leading-7'>
                        <td className='text-gray-500 w-[42%]'>Branch ID: </td>
                        <td>{formData?.branchID}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className='bg-bgclr9 lg:w-[50%] px-5 py-3 border-[1px] border-[#000000] border-opacity-[20%] rounded-md'>
                  <div className='flex text-[18px] leading-10'>
                    <table className='w-full'>
                      <tr className='flex items-start mb-3 leading-7'>
                        <td className='text-gray-500 w-[42%]'>Branch IFSC Code: </td>
                        <td>{formData?.IFSCCode}</td>
                      </tr>
                      <tr className='flex items-start mb-3 leading-7'>
                        <td className='text-gray-500 w-[42%]'>Branch Contact no. : </td>
                        <td>
                          {formData?.branchContact}
                        </td>
                      </tr>
                      <tr className='flex items-start mb-3 leading-7'>
                        <td className='text-gray-500 w-[42%]'>Branch Location: </td>
                        <td>{formData?.location}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit} className='mt-5'>
                <div className='flex justify-end'>
                  <span className='text-red-600 font-semibold text-md'>
                    [* Indicates a required field]
                  </span>
                </div>
                <div className='flex justify-between items-center'>
                  <div className='text-gray-500 my-6 font-semibold text-xl'>
                    Branch Manager Details
                  </div>
                  <div className=''>
                    <button
                      type='button'
                      onClick={toggleEdit}
                      className='bg-bgclr hover:bg-btnclr rounded-md py-2 text-white px-7 font-semibold'
                    >
                      {isEditable ? 'Cancel' : 'Edit'}
                    </button>
                  </div>
                </div>
                <div className='grid md:grid-cols-2 md:gap-10 md:mt-2'>
                  {/* Manager Name */}
                  <div className='mb-4'>
                    <label
                      htmlFor='managerName'
                      className='block text-sm font-medium text-txtclr2'
                    >
                      Branch Manager Name<span className='text-red-600'>*</span>
                    </label>
                    <input
                      type='text'
                      placeholder='Branch Manager Name'
                      id='managerName'
                      name='managerName'
                      value={formData.managerName}
                      onChange={handleChange}
                      className={`mt-1 p-2 w-full border-b-2 border-gray-400 ${
                        isEditable ? 'bg-white' : 'bg-gray-100'
                      }`}
                      disabled={!isEditable}
                    />
                    {nameErr && (
                      <span className='text-red-600 font-semibold text-[14px]'>
                        {nameErr}
                      </span>
                    )}
                  </div>
                  <div className='mb-4'>
                    <label
                      htmlFor='managerPhone'
                      className='block text-sm font-medium text-txtclr2'
                    >
                      Branch Manager Mobile No.
                      <span className='text-red-600'>*</span>
                    </label>
                    <input
                      type='text'
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '')
                      }}
                      maxLength='10'
                      placeholder='Branch Manager Mobile No.'
                      id='managerPhone'
                      name='managerPhone'
                      value={formData.managerPhone}
                      onChange={handleChange}
                      className={`mt-1 p-2 w-full border-b-2 border-gray-400 ${
                        isEditable ? 'bg-white' : 'bg-gray-100'
                      }`}
                      disabled={!isEditable}
                    />
                    {phoneErr && (
                      <span className='text-red-600 font-semibold text-[14px]'>
                        {phoneErr}
                      </span>
                    )}
                  </div>
                </div>

                <div className='grid md:grid-cols-2 md:gap-10 md:mt-2'>
                  {/* Manager Employee ID */}
                  <div className='mb-4'>
                    <label
                      htmlFor='managerEmpId'
                      className='block text-sm font-medium text-txtclr2'
                    >
                      Branch Manager Employee ID
                      <span className='text-red-600'>*</span>
                    </label>
                    <input
                      type='text'
                      placeholder='Branch Manager Employee ID'
                      id='managerEmpId'
                      name='managerEmpId'
                      value={formData.managerEmpId}
                      onChange={handleChange}
                      className={`mt-1 p-2 w-full border-b-2 border-gray-400 ${
                        isEditable ? 'bg-white' : 'bg-gray-100'
                      }`}
                      required
                      disabled={!isEditable}
                    />
                  </div>
                  <div className='mb-4'>
                    <label
                      htmlFor='managerEmpEmail'
                      className='block text-sm font-medium text-txtclr2'
                    >
                      Branch Manager Employee Email
                      <span className='text-red-600'>*</span>
                    </label>
                    <input
                      type='text'
                      placeholder='Branch Manager Employee Email'
                      id='managerEmpEmail'
                      name='managerEmpEmail'
                      value={formData.managerEmpEmail}
                      onChange={handleChange}
                      className={`mt-1 p-2 w-full border-b-2 border-gray-400 ${
                        isEditable ? 'bg-white' : 'bg-gray-100'
                      }`}
                      disabled={!isEditable}
                    />
                    {emailErr && (
                      <span className='text-red-600 font-semibold text-[14px]'>
                        {emailErr}
                      </span>
                    )}
                  </div>
                </div>

                {isEditable && (
                  <div className='flex items-center justify-center mt-5'>
                    <button
                      type='submit'
                      className='px-12 py-2 text-white bg-bgclr w-full rounded-md font-bold hover:bg-btnclr focus:outline-none focus:ring focus:ring-indigo-200'
                      disabled={isUpdate}
                    >
                      {isUpdate ? (
                        <div className='flex justify-center items-center'>
                          <FaSpinner className='animate-spin text-3xl' />
                        </div>
                      ) : (
                        'Save Changes'
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BankerProfile
