import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'

const OTPPopup = ({
  isOpen,
  onClose,
  otpLength,
  email,
  otp,
  setOTP,
  handleOTPVerification,
  timer,
  setTimer,
  setShowResend,
  showResend,
  handleEdit,
  handleResendClick,
  isLoadingVerify,
  OTPError,
  setOTPError
}) => {
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    if (OTPError) {
      const timer = setTimeout(() => {
        setOTPError('')
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [OTPError])

  useEffect(() => {
    if (isOpen) {
      // Reset timer
      setTimer(60) // Reset timer to 10 seconds
      setShowResend(false)

      // Start timer countdown
      const interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            clearInterval(interval)
            setShowResend(true)
            return 0
          }
          return prevTimer - 1
        })
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [isOpen])

  useEffect(() => {
    console.log('Otp length:-- ', otp.length, otpLength)
    if (otp?.length == otpLength) {
      setComplete(true)
    } else {
      setComplete(false)
    }
  }, [otp?.length])

  return (
    <Popup open={isOpen} modal onClose={onClose} closeOnDocumentClick={false}>
      <div className='popup-content font-avenir fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm'>
        <div className='bg-bgclr2 md:w-[400px] w-[340px] py-3 pb-10 mx-auto h-auto overflow-y-auto rounded-[14px]'>
          <div className='flex justify-between font-avenir text-txtclr px-10 mb-3'>
            <h1 className='text-[20px] font-[600] '>
              Verify OTP
            </h1>
            <button
              type='submit'
              onClick={() => {
                handleEdit()
              }}
              className='text-xl font-bold'
            >
              X
            </button>
          </div>
          <div className='mx-auto max-h-screen'>
            <div className='bg-txtclr py-1 mx-10 rounded-[14px]'>
              <div className='mx-2 md:rounded-3xl'>
                <div className='md:p-4 p-2 rounded'>
                  {OTPError && (
                    <div className='text-red-600 text-lg font-semibold mb-1'>
                      {OTPError}
                    </div>
                  )}
                  <div className='md:text-[16px]'>
                    <h1 className='font-semibold'>{otpLength === 4 ? 'OTP sent to your GST Registered Mobile Number*' : 'OTP sent to your Entered Mobile Number*'}</h1>
                  </div>
                  <h2 className='block text-[14px] font-medium text-txtclr2 my-1'>
                    Enter OTP
                  </h2>
                  <form onSubmit={handleOTPVerification}>
                    <div>
                      <input
                        type='password'
                        maxLength={otpLength}
                        value={otp}
                        onInput={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                        }}
                        onChange={e => setOTP(e.target.value)}
                        className={`mt-1 p-2 w-full rounded-md ${
                          complete
                            ? 'ring-green-600 ring-2 bg-green-50'
                            : 'ring-gray-300 ring-2'
                        }`}
                      />
                    </div>
                    <div className='flex justify-center'>
                      <button
                        type='submit'
                        className={`flex mt-4 text-txtclr w-32 justify-center py-2 rounded ${
                          complete ? 'bg-bgclr' : 'bg-txtclr2'
                        }`}
                        disabled={isLoadingVerify || !complete}
                      >
                        {isLoadingVerify ? 'Verifying....' : 'Verify OTP'}
                      </button>
                    </div>
                    <div className='mt-2 text-center text-[14px]'>
                      {timer > 0 && (
                        <div className=' text-gray-700'>
                          Resend OTP in{' '}
                          <span className='text-green-800 font-semibold'>
                            {' '}
                            {Math.floor(timer / 60)}:
                            {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                          </span>
                        </div>
                      )}
                      {showResend && (
                        <div>
                          <button
                            className='ml-2 text-txtclr3 underline'
                            onClick={handleResendClick}
                          >
                            Resend OTP
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default React.memo(OTPPopup)
