
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


import { HiShieldCheck } from 'react-icons/hi';

const ServeNoticeSuccess = () => {
    const navigate = useNavigate();

    const NavigateComponent = () => {
        navigate('/banking/bankDashboard/serviceNotice')
    }

    return (
        <>
            <div className='root-style mt-0'>
                <div className='flex justify-center h-screen items-center'>
                    <div className='text-center'>
                        <h1 className='flex md:text-[40px] text-[24px] text-green-600 font-semibold'><span className='drop-shadow-lg shadow-black'>Your Notice has been sent</span> <HiShieldCheck className='ml-2 md:w-[40px] w-[30px] h-[30px] md:h-[50px] mt-2' /></h1>
                        <p className='md:text-2xl text-lg text-gray-600 font-semibold'>Please download the POD after 24 hours.</p>
                        <div className='flex justify-center lg:mt-6 mt-4'>
                            <button onClick={() => NavigateComponent()} className={`md:text-xl text-md px-10 py-3 flex justify-center items-center rounded-md bg-bgclr text-txtclr`}>
                                Go To Dashboard
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};

export default ServeNoticeSuccess;
