import { jwtDecode } from "jwt-decode";

export const decodeJwtToken = (token) => {
    if (!token) {
        console.error("JWT token is missing");
        return null;
    }

    try {
        return jwtDecode(token);
    } catch (error) {
        console.error("Error decoding JWT:", error);
        return null;
    }
};