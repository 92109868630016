import React, { useEffect, useState } from 'react'
import { HiShieldCheck } from 'react-icons/hi'
import { useLocation, useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../../Banking-Plateform/Banking-Dashboard/Pages/Widget'
import Loader from '../../../../CommonFunction/Loader'
import { showToast } from '../../../../CommonFunction/Toast'

const VerifyUser = () => {
  const [userData, setUserData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [docType, setDocType] = useState('')
  const [docDate, setDocDate] = useState('')
  const [podData, setPodData] = useState([])
  const [updatedDate, setUpdatedDate] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const envelopeID = location.state?.envelopeID
  const searchParams = new URLSearchParams(location.search)
  const envelope_ID = searchParams.get('number')

  useEffect(() => {
    const fetchVerifiedUser = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(
          `${
            process.env.REACT_APP_PLATFORM_API_BASE_URL
          }/verification?envelope_id=${envelopeID || envelope_ID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        setIsLoading(false)
        const data = await response.json()

        if (response.ok) {
          setDocDate(data?.updated_at)
          setUserData(data?.envelope[0])
          setPodData(data?.notice_response)
          setUpdatedDate(data?.updated_at)
          // Set docType based on userData
          if (data && data?.envelope[0].docType === 'Pod') {
            setDocType('Proof of Delivery of Banking Platform')
          } else {
            setDocType('Proof of Registration of Banking Platform')
          }
        } else {
          showToast('Envelope ID not found', 'error')
          navigate('/verification')
        }
      } catch (error) {
        throw new Error('Error retrieving user data:', error)
      }
    }
    fetchVerifiedUser()
  }, [setUserData])

  if (isLoading) {
    // Loading state
    return <Loader />
  }

  const maskDetails = (type, value) => {
    switch (type) {
      case 'UCI':
        return `****${value.slice(-4)}`
      case 'Aadhaar':
        return `********${value.slice(-4)}`
      case 'Email':
        return maskEmail(value)
      case 'Mobile':
      case 'WhatsApp':
      case 'PAN':
        return `${'*'.repeat(value.length - 3)}${value.slice(-3)}`
      case 'Address':
        return '*'.repeat(value.length)
      case 'Envelope_id':
        return `*********${value.slice(-4)}`
      default:
        return value
    }
  }

  const maskEmail = email => {
    if (typeof email !== 'string') {
      console.error('Email is not a string or is undefined:', email)
      return '' // Return an empty string if email is not a string or is undefined
    }

    const atIndex = email.indexOf('@')

    if (atIndex === -1) {
      console.error("Email is missing '@' symbol:", email)
      return '' // Return an empty string if email does not contain '@'
    }

    const [username, domain] = email.split('@')

    let maskedUsername = ''
    if (username.length <= 3) {
      maskedUsername =
        username.substring(0, 1) + '*'.repeat(username.length - 1)
    } else if (username.length <= 6) {
      maskedUsername =
        username.substring(0, 2) + '*'.repeat(username.length - 2)
    } else {
      maskedUsername =
        username.substring(0, 2) +
        '*'.repeat(username.length - 6) +
        username.substring(username.length - 4, username.length - 1)
    }

    const maskedDomain =
      domain.substring(0, 2) +
      '*'.repeat(domain.length - 4) +
      domain.substring(domain.length - 3)
    return `${maskedUsername}@${maskedDomain}`
  }

  const formatDate = isoString => {
    const date = new Date(isoString)
    const options = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }
    return date.toLocaleString('en-US', options)
  }

  const borrowerName = () => {
    if (userData && userData.user) {
      return `${capitalizeFirstLetter(
        userData.user.first_name || ''
      )} ${capitalizeFirstLetter(
        userData.user.middle_name || ''
      )} ${capitalizeFirstLetter(userData.user.last_name || '')}`
    } else if (userData && userData.organization) {
      return capitalizeFirstLetter(userData.organization.name || '')
    } else {
      return ''
    }
  }

  return (
    <>
      <div className='bg-gray-200 mt-20'>
        <div className='container mx-auto py-20'>
          <div className='flex md:py-7 pt-10   md:pl-10 items-center lg:justify-center justify-center'>
            <h1 className='flex font-bold md:text-[44px] text-3xl font-avenir text-green-700'>
              Document Verified <HiShieldCheck size={38} className='ml-2' />
            </h1>
          </div>
          {userData?.docType === 'Pod' ? (
            <div className='w-[950px] mx-auto mt-5'>
              <div className='pb-2 mx-8'>
                <div>
                  <div className='border bg-bgclr9 mb-20 max-w-5xl'>
                    <div className='flex bg-bgclr px-10 py-5 justify-between'>
                      <div>
                        <img
                          className='w-64 ml-3'
                          src='https://cdn.clevdoc.com/assets/logo/clevdoc_logo.png'
                          alt='Not available'
                        />
                      </div>
                    </div>

                    <div className='mt-5 mb-7'>
                      <h1 className='text-center text-4xl font-bold text-txtclr3'>
                        PROOF OF DELIVERY
                      </h1>
                    </div>

                    <div className='flex justify-between'>
                      <h1 className='text-xl ml-16 pb-2 text-txtclr2 underline font-semibold'>
                        Details
                      </h1>
                    </div>
                    <div className='mx-10 mb-4 pb-7 pt-1 border border-gray-600 rounded h-auto '>
                      <div className='flex justify-end '>
                        <h2 className='text-sm text-gray-400 mr-5 mt-1'>
                          Updated on :- {formatDate(updatedDate)}
                        </h2>
                      </div>
                      <table className='text-md ml-8'>
                        <tr>
                          <td>Name:</td>
                          <td className='pl-8 font-semibold'>{borrowerName()}</td>
                        </tr>
                        <tr>
                          <td>ClevDoc Envelope Id : </td>
                          <td className='pl-8 font-semibold'>
                            {maskDetails('Envelope_id', `${userData?.envelope_id}`)}
                          </td>
                        </tr>
                        <tr className=''>
                          <td className='flex items-start justify-start'>
                            Document Type :{' '}
                          </td>
                          <td className='pl-8 font-semibold'>{docType}</td>
                        </tr>
                      </table>

                      <div className='p-3 border mx-5 mt-5'>
                        <div className='grid grid-cols-2 mb-5 mr-4 gap-5'>
                          <div className='grid border-r pr-3'>
                            <div className='text-[20px] font-semibold mb-2'>
                              <h2>Email :</h2>
                            </div>
                            <table className='text-md'>
                              <tr>
                                <td className='w-[50%]'>Delivery Address : </td>
                                <td className='font-semibold'>
                                  {userData &&
                                    maskDetails(
                                      'Email',
                                      `${
                                        userData?.user?.email ||
                                        userData?.organization?.email
                                      }`
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className='w-[50%]'>
                                  Delivery (time/date) :{' '}
                                </td>
                                <td className='font-semibold'>
                                  {formatDate(podData[0]?.updated_at) || 'N/A'}
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className='grid'>
                            <div className='text-[20px] font-semibold mb-2'>
                              <h2>SMS :</h2>
                            </div>
                            <table className='text-md'>
                              <tr>
                                <td className='w-[50%]'>Delivery Number : </td>
                                <td className='font-semibold'>
                                  {userData &&
                                    maskDetails(
                                      'Mobile',
                                      `${
                                        userData?.user?.phone_number ||
                                        userData?.organization?.phone_number
                                      }`
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className='w-[50%]'>
                                  Delivery (time/date) :{' '}
                                </td>
                                <td className='font-semibold'>
                                  {formatDate(podData[3]?.updated_at) || 'N/A'}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <hr />
                        <div className='grid grid-cols-2 mt-5 gap-10'>
                          <div className='grid border-r'>
                            <div className='text-[20px] font-semibold mb-2'>
                              <h2>WhatsApp :</h2>
                            </div>
                            <table className='text-md'>
                              <tr>
                                <td className='w-[50%]'>Delivery Number : </td>
                                <td className='font-semibold'>
                                  {userData &&
                                    maskDetails(
                                      'Mobile',
                                      `${
                                        userData?.user?.personal?.whatsapp ||
                                        userData?.organization?.personal
                                          ?.whatsapp
                                      }`
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className='w-[50%]'>
                                  Delivery (time/date) :{' '}
                                </td>
                                <td className='font-semibold'>
                                  {formatDate(podData[2]?.updated_at) || 'N/A'}
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className='grid'>
                            <div className='text-[20px] font-semibold mb-2'>
                              <h2>IVR :</h2>
                            </div>
                            <table className='text-md'>
                              <tr>
                                <td className='w-[50%]'>Delivery Number : </td>
                                <td className='font-semibold'>
                                  {userData &&
                                    maskDetails(
                                      'Mobile',
                                      `${
                                        userData?.user?.phone_number ||
                                        userData?.organization?.phone_number
                                      }`
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className='w-[50%]'>Initiated Date : </td>
                                <td className='font-semibold'>
                                  {formatDate(podData[1]?.updated_at) || 'N/A'}
                                </td>
                              </tr>
                              <tr>
                                <td className='w-[50%]'>Last Called : </td>
                                <td className='font-semibold'>
                                  {formatDate(
                                    podData[1]?.last_response?.start_time
                                  ) || 'N/A'}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mx-12'>
                      <div className='text-[25px] font-semibold mb-5'>
                        <h1>Remarks :-</h1>
                      </div>
                      <div>
                        <ol className='list-inside list-decimal leading-7 text-lg pl-5 pb-5'>
                          {podData[3]?.status === 'sent' ? (
                            <li>
                              SMS was <b>Sent and Delivered</b> (number switched
                              on).
                            </li>
                          ) : (
                            <li>
                              SMS was <b>not sent</b>.
                            </li>
                          )}

                          {podData[0]?.status === 'sent' ? (
                            <div>
                              {podData[0]?.last_response?.email_status ===
                              'open' ? (
                                <li>
                                  Email was <b>Sent and Read </b>by borrower.
                                </li>
                              ) : (
                                <li>
                                  Email was <b>sent</b> & did not bounce back.
                                </li>
                              )}
                            </div>
                          ) : (
                            <li>
                              Email was <b>Sent</b>.
                            </li>
                          )}

                          {podData[2]?.last_response?.status === 'read' ? (
                            <li>
                              Whatsapp message was{' '}
                              <b>Sent, Delivered and Read</b> by Borrower.
                            </li>
                          ) : podData[2]?.last_response?.status ===
                            'delivered' ? (
                            <li>
                              Whatsapp message was <b>Sent and Delivered</b> to
                              Borrower.
                            </li>
                          ) : podData[2]?.last_response?.status ===
                            'delivered' ? (
                            <li>
                              Whatsapp was <b>Sent</b> to the borrower.
                            </li>
                          ) : (
                            <li>
                              Whatsapp message <b>wasn't sent</b>.
                            </li>
                          )}

                          {podData[1]?.last_response?.customer_keypress ===
                          '2' ? (
                            <li>
                              IVR call was made, acknowledgement for receipt &{' '}
                              <b>communication acknowledged</b> by Borrower.
                            </li>
                          ) : (
                            <li>
                              IVR call made, acknowledgement for receipt &{' '}
                              <b>communication wasn't acknowledged</b> by
                              Borrower.
                            </li>
                          )}
                        </ol>
                      </div>
                    </div>

                    <div className='grid grid-cols-5 bg-gray-300 p-5 px-10 gap-10'>
                      <div className='col-span-3'>
                        <div>
                          <h1 className='text-2xl font-bold text-txtclr3'>
                            ClevDoc Platforms Inc.
                          </h1>
                        </div>
                        <div>
                          <p className='flex text-justify text-lg pt-5 border-r-2 pr-10 border-bgclr2'>
                            To verify the authenticity of this Registration
                            Certificate, please scan the QR or visit the
                            https/clevdoc.com/verify. In case of any queries,
                            you can email us at support@clevdoc.com
                          </p>
                        </div>
                      </div>
                      <div className='grid pl-8'>
                        <div className='flex items-center w-56'>
                          <img
                            src={`https://api.qrserver.com/v1/create-qr-code/?data=https://www.clevdoc.com/verifyUser?number=${userData?.envelope_id}&size=200x200`}
                            alt='not available'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='lg:mx-44 mx-2 bg-bgclr9 py-10 rounded-lg border border-bgclr3'>
              <div className='flex justify-between'>
                <h1 className='text-lg lg:ml-28 ml-5 pb-2 text-txtclr2 underline font-semibold'>
                  ClevDoc Envelope Id : {envelopeID || envelope_ID}
                </h1>
              </div>
              <div className='lg:mx-28 md:pl-8 pl-3 mb-4 lg:border-2 rounded-xl '>
                <table className='text-md mb-5 leading-8'>
                  <tr className=''>
                    <td className='flex items-start justify-start'>
                      Document Type :{' '}
                    </td>
                    <td className='pl-8 font-semibold'>{docType}</td>
                  </tr>
                  <tr className=''>
                    <td>Date of Generation : </td>
                    <td className='pl-8 font-semibold'>
                      {formatDate(docDate)}
                    </td>
                  </tr>
                  <tr className=''>
                    <td>UCI : </td>
                    <td className='pl-8 font-thin'>
                      {maskDetails(
                        'UCI',
                        `${
                          userData?.user?.system_id ||
                          userData?.organization?.system_id
                        }`
                      )}
                    </td>
                  </tr>
                  <tr className=''>
                    <td>Name of Borrower : </td>
                    <td className='pl-8 font-semibold'>{borrowerName()}</td>
                  </tr>
                  {userData?.organization && (
                    <tr className=''>
                      <td>Organization Type : </td>
                      <td className='pl-8 font-semibold'>
                        {userData?.organization?.type}
                      </td>
                    </tr>
                  )}
                  {userData?.user && (
                    <tr>
                      <td>Gender : </td>
                      <td className='pl-8 font-semibold'>
                        {capitalizeFirstLetter(userData?.user?.gender)}
                      </td>
                    </tr>
                  )}
                  {userData?.user && (
                    <tr>
                      <td>Aadhaar No. : </td>
                      <td className='flex pl-8 font-semibold'>
                        {maskDetails(
                          'Aadhaar',
                          `${userData?.user?.personal?.adhaar_card?.number}`
                        )}
                        <HiShieldCheck
                          size={23}
                          className='mt-0.5 text-green-700'
                        />
                      </td>
                    </tr>
                  )}
                  {userData?.organization && (
                    <tr>
                      <td>GST No. : </td>
                      <td className='flex pl-8 font-semibold'>
                        {maskDetails(
                          'Aadhaar',
                          `${userData?.organization?.personal?.gst_number}`
                        )}
                        <HiShieldCheck
                          size={23}
                          className='mt-0.5 text-green-700'
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Email ID : </td>
                    <td className='flex pl-8 font-semibold'>
                      {userData &&
                        maskDetails(
                          'Email',
                          `${
                            userData?.user?.email ||
                            userData?.organization?.email
                          }`
                        )}
                      <HiShieldCheck
                        size={23}
                        className='mt-0.5 text-green-700'
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile No. : </td>
                    <td className='flex pl-8 font-semibold'>
                      {userData &&
                        maskDetails(
                          'Mobile',
                          `${
                            userData?.user?.phone_number ||
                            userData?.organization?.phone_number
                          }`
                        )}
                      <HiShieldCheck
                        size={23}
                        className='mt-0.5 text-green-700'
                      />
                    </td>
                  </tr>
                  {userData?.user && (
                    <tr>
                      <td>WhatsApp : </td>
                      <td className='flex pl-8 font-semibold'>
                        {userData &&
                          maskDetails(
                            'WhatsApp',
                            `${userData?.user?.personal?.whatsapp}`
                          )}
                        <HiShieldCheck
                          size={23}
                          className='mt-0.5 text-green-700'
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>PAN Number : </td>
                    <td className='flex pl-8 font-semibold'>
                      {userData &&
                        maskDetails(
                          'PAN',
                          `${
                            userData?.user?.personal?.pancard ||
                            userData?.organization?.personal?.pancard
                          }`
                        )}
                      <HiShieldCheck
                        size={23}
                        className='mt-0.5 text-green-700'
                      />
                    </td>
                  </tr>

                  {userData?.organization && (
                    <tr className=''>
                      <td>Authorized Person Name : </td>
                      <td className='pl-8 font-semibold'>
                        {userData?.organization?.authorized_person}
                      </td>
                    </tr>
                  )}

                  {userData?.organization && (
                    <tr className=''>
                      <td>Designation : </td>
                      <td className='pl-8 font-semibold'>
                        {userData?.organization?.designation}
                      </td>
                    </tr>
                  )}

                  {userData?.user && (
                    <tr>
                      <td>Current Address :</td>
                      <td className='pl-8 font-semibold'>
                        {userData &&
                          maskDetails(
                            'Address',
                            `${userData?.user?.address?.current_address}`
                          )}
                      </td>
                    </tr>
                  )}
                  {userData?.user && (
                    <tr>
                      <td>Permanent Address :</td>
                      <td className='pl-8 font-semibold'>
                        {userData &&
                          maskDetails(
                            'Address',
                            `${userData?.user?.address?.permanent_address}`
                          )}
                      </td>
                    </tr>
                  )}
                  {userData?.organization && (
                    <tr>
                      <td>Office Address :</td>
                      <td className='pl-8 font-semibold'>
                        {userData &&
                          maskDetails(
                            'Address',
                            `${userData?.user?.address?.office_address}`
                          )}
                      </td>
                    </tr>
                  )}
                  {userData?.organization && (
                    <tr>
                      <td>Registered Address :</td>
                      <td className='pl-8 font-semibold'>
                        {userData &&
                          maskDetails(
                            'Address',
                            `${userData?.user?.address?.registered_address}`
                          )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Alternate Number : </td>
                    <td className='pl-8 font-semibold'>
                      {userData &&
                        maskDetails(
                          'Mobile',
                          `${
                            userData?.user?.alternate_contacts?.phone_number ||
                            userData?.organization?.alternate_contacts
                              ?.phone_number
                          }`
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>Alternate Email ID :</td>
                    <td className='pl-8 font-semibold'>
                      {userData &&
                        maskDetails(
                          'Email',
                          `${
                            userData?.user?.alternate_contacts?.email ||
                            userData?.organization?.alternate_contacts?.email
                          }`
                        )}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default VerifyUser
