import React, { useEffect, useState } from 'react'
import { usePagination, useTable } from 'react-table'
import cookies from '../../../../../CommonFunction/cookies'
import DataTable from '../../Elements/DataTable'
import Pagination from '../../Elements/Pagination'
import EmptyDataAlert from '../../Elements/EmptyDataAlert'
import Loader from '../../../../../CommonFunction/Loader'
import { fetchOutboxList } from '../../../../../API/Bank/Outbox'

const BankOutbox = () => {
  const jwtToken = cookies.get('jwt')
  const [loading, setLoading] = useState(true)
  const [loanData, setLoanData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [pagination, setPagination] = useState({
    totalDocs: 0,
    totalPages: 1,
    page: 1,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null
  })

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const data = await fetchOutboxList(jwtToken, pagination.page, searchQuery)
      if (data?.code === 400) {
        setLoanData([])
      } else {
        setLoanData(data.docs)
        setPagination({
          totalDocs: data.totalDocs,
          totalPages: data.totalPages,
          page: data.page,
          limit: data.limit,
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage,
          nextPage: data.nextPage,
          prevPage: data.prevPage
        })
      }
    } catch (error) {
      setSearchQuery('')
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async () => {
    fetchData()
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Received On',
        accessor: 'notices.created_at',
        Cell: ({ value }) => (
          <span className='flex justify-center'>
            {new Date(value).toLocaleDateString()}
          </span>
        )
      },
      {
        Header: 'Name',
        accessor: row => {
          if (row.organization_details) {
            return row.organization_details.name
          }
          if (row.user_details) {
            const { first_name, middle_name, last_name } = row.user_details
            return [first_name, middle_name, last_name]
              .filter(Boolean)
              .join(' ')
          }
          return 'Null'
        },
        Cell: ({ value }) => (
          <div className='text-center border-bgclr2'>
            <div className='border-bgclr2 mx-4'>{value}</div>
          </div>
        )
      },
      {
        Header: 'UCID',
        accessor: row =>
          row.organization_details
            ? row.organization_details.system_id
            : row.user_details
            ? row.user_details.system_id
            : 'Null',
        Cell: ({ value }) => (
          <div className='text-center border-bgclr2'>
            <div className='border-bgclr2 mx-4'>{value}</div>
          </div>
        )
      },
      {
        Header: 'Subject',
        accessor: 'notices.subject',
        Cell: ({ value }) => (
          <div className='text-center text-wrap'>{value || 'Null'}</div>
        )
      },
      {
        Header: 'Linked Loan Number',
        accessor: 'notices.loan_id',
        Cell: ({ value }) => (
          <div className='text-center'>{value || 'Null'}</div>
        )
      },
      {
        Header: 'Download Link',
        accessor: 'notices.download_link',
        Cell: ({ value }) => (
          <a
            href={value}
            target='_blank'
            rel='noopener noreferrer'
            className='bg-btnclr px-2 py-2 text-sm flex justify-center rounded-md shadow-lg hover:bg-btnhover text-txtclr'
          >
            Download
          </a>
        )
      }
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data: loanData,
        initialState: { pageIndex: pagination.page - 1 },
        pageCount: pagination.totalPages,
        manualPagination: true
      },
      usePagination
    )

  const nextPage = async () => {
    const nextPageIndex = pagination.page + 1;
    if (nextPageIndex <= pagination.totalPages) {
      setLoading(true);
      try {
        const data = await fetchOutboxList(jwtToken, nextPageIndex, searchQuery); // Added `await`
        setLoanData(data?.docs || []); // Fallback to empty array
        setPagination((prevState) => ({
          ...prevState,
          page: nextPageIndex,
          hasNextPage: data?.hasNextPage || false,
          hasPrevPage: data?.hasPrevPage || false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  

  const previousPage = async() => {
    const prevPageIndex = pagination.page - 1
    if (prevPageIndex >= 1) {
      setLoading(true);
      try {
        const data = await fetchOutboxList(jwtToken, prevPageIndex, searchQuery); // Added `await`
        setLoanData(data?.docs || []); // Fallback to empty array
        setPagination((prevState) => ({
          ...prevState,
          page: prevPageIndex,
          hasNextPage: data?.hasNextPage || false,
          hasPrevPage: data?.hasPrevPage || false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <div className='root-style'>
        <div>
          <div className='searchBar-style'>
            <input
              type='search'
              placeholder='Search'
              className='searchBar-input'
              onChange={e => setSearchQuery(e.target.value)}
            />
            <button onClick={handleSearch} className='searchBar-btn'>
              Search
            </button>
          </div>
          {loading ? (
            <div>
              <Loader />
            </div>
          ) : loanData.length === 0 ? (
            <EmptyDataAlert title='You have no outgoing messages yet.' />
          ) : (
            <div className='my-6'>
              <div className='pt-3 overflow-x-auto'>
                <DataTable
                  getTableProps={getTableProps}
                  headerGroups={headerGroups}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  prepareRow={prepareRow}
                />
              </div>
              <Pagination
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={pagination.hasPrevPage}
                canNextPage={pagination.hasNextPage}
                pagination={{
                  page: pagination.page,
                  totalPages: pagination.totalPages
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default BankOutbox
