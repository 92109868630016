import React from 'react'
import FAQs from '../../Banking-Landing-page/Elements/FAQs'
import { MdPhoneInTalk } from 'react-icons/md';
import { IoMailOutline } from 'react-icons/io5';
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';

const BankHelp = () => {

  const cardData = [
    {
      link: 'tel:9876765678',
      icon: <MdPhoneInTalk size={35} />,
      heading: 'IVR Call',
      para: '+(845)-5423-635',
      detail: "Connect  instantly using our IVR system."
    },
    {
      link: 'mailto:support@clevdoc.com',
      icon: <IoMailOutline size={35} />,
      heading: 'Email',
      para: 'support@clevdoc.com',
      detail: "Reach out via our Mail tab for detailed correspondence.",
    },
    {
      link: 'https://wa.me/9811811272',
      icon: <FaWhatsapp size={35} />,
      heading: 'WhatsApp',
      para: '+91-9811811272',
      detail: "Ping on our WhatsApp number for detailed correspondence.",
    }
  ];

  return (
    <>
      <div className='root-style'>
        <div className=''>
          <div className='pb-5 pt-10'>
            <div className='flex justify-center'>
              <h1 className='pl-4 pt-3 pb-2 text-2xl font-bold text-txtclr3'>Hello, How can we help you?</h1>
            </div>
            <div className='lg:w-3/5 w-4/5 mx-auto text-center'>
              <p>Greetings! For prompt, personalized assistance, share your needs, questions, or feedback with us. We're here to help and committed to providing you with the support you require.
              </p>
            </div>
            <div className='flex'>
              <form className="w-full md:max-w-2xl mx-auto py-5">
                <div className='md:pb-0 lg:w-2/3 mx-auto pb-3'>
                  <label htmlFor="name" className="block text-sm font-medium text-txtclr2">
                    Name<span className='text-red-600'>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder='Name'
                    id="name"
                    name="name"
                    // value="surya.pratap@gmail.com"
                    // onChange={handleChange}
                    className="input-style"
                    required
                  />
                </div>
                <div className='pt-3'>
                  <div className='mb-3 lg:w-2/3 mx-auto'>
                    <label htmlFor="email" className="block text-sm font-medium text-txtclr2">
                      Email<span className='text-red-600'>*</span>
                    </label>
                    <input
                      type="email"
                      placeholder='Email'
                      id="email"
                      name="email"
                      // value={formData.lname}
                      // onChange={handleChange}
                      className="input-style"
                      required
                    />
                  </div>
                  <div className="md:gap-6">
                    <div className='md:pb-0 pb-3 lg:w-2/3 mx-auto'>
                      <label htmlFor="msg" className="block text-sm font-medium text-txtclr2">
                        Message<span className='text-red-600'>*</span>
                      </label>
                      <textarea
                        type="text"
                        placeholder='Enter your question here...'
                        id="msg"
                        name="msg"
                        // value={formData.fname}
                        // onChange={handleChange}
                        className="input-style"
                        required
                      />
                    </div>
                  </div>
                  <div className='lg:ml-24' >
                    <button type='submit' className="mt-2  bg-txtclr2 hover:bg-bg-btnhover text-txtclr px-4 py-2 rounded">
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className='bg-bgclr3'>
            <div className='pb-5 pt-3 text-txtclr'>
              <div className='flex justify-center'>
                <h1 className='pl-4 pt-3 pb-2 text-2xl font-bold text-txtclr'>You still have a question?</h1>
              </div>
              <div className='w-1/2 mx-auto text-center'>
                <p>If you have a question and can't find the answer, simply fill out the query form and submit it. Alternatively, you can contact us directly, and we'll respond to your inquiry promptly.
                </p>
              </div>
            </div>
            <div className='flex justify-center'>
              <div className='md:flex gap-5 flex-col mb-5 lg:flex-row lg:w-[700px] py-5'>
                {cardData.map((data, index) => (
                  <div className=''>
                    <a href={data.link} target="_blank">
                      <div className="p-4 lg:w-[220px] w-[310px] mx-auto h-full mb-5 justify-center bg-gray-100 border border-gray-200 rounded-lg shadow-2xl  dark:bg-gray-800 dark:border-gray-700">
                        <div className='flex justify-center text-txtclr3'>
                          {data.icon}
                        </div>
                        <div className='flex justify-center'>
                          <h5 className="text-2xl font-semibold tracking-tight text-txtclr3 dark:text-white">{data.heading}</h5>
                        </div>
                        <div className='flex justify-center'>
                          <p className="font-normal text-gray-500 dark:text-gray-400">{data.para}</p>
                        </div>
                        <div className="flex justify-center ">
                          <p className='text-center'>{data.detail}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='pb-5'>
            <FAQs />
          </div>
        </div>
      </div>
    </>
  )
}

export default BankHelp