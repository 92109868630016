import React, { useEffect, useState } from 'react'
import cookies from '../../../../../CommonFunction/cookies'
import {
  fetchIndividualData,
  fetchOrganizationData
} from '../../../../../API/ClevDocContact'
import { decodeJwtToken } from '../../../../../CommonFunction/TokenDecode'
import { ProceedToPay } from '../../../../../API/PaymentAPI'
import Loader from '../../../../../CommonFunction/Loader'
import { FaSpinner } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { showWarning } from '../../../../../CommonFunction/SweetAlert'
import PaymentProcess from './PaymentProcess'

const PurchaseSummary = () => {
  const [userData, setUserData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [payClicked, setPayClicked] = useState(false)
  const [sameState, setSameState] = useState(false)
  const navigate = useNavigate()
  const jwtToken = cookies.get('jwt')
  const [userId, setUserId] = useState('')

  const fetchDataAndUpdateUserData = async () => {
    setIsLoading(true)
    try {
      const data = await fetchIndividualData(jwtToken)
      setUserData(data)
      if (data?.address?.state === 'Haryana') {
        setSameState(true)
      }
      if (data && data?.step === 1) {
        showWarning(
          'Profile Incomplete...',
          'Your Profile is not completed, please complete your profile first'
        )
        navigate('/banking/borrowerDashboard/1')
      } else if (data && data?.step === 2 && !data?.payment) {
        navigate('/banking/purchaseSummary')
      } else if (data && data?.step === 3 && data?.payment) {
        navigate('/banking/borrowerDashboard/dashboard')
      }
    } catch (error) {
      console.error('Error retrieving user data:', error)
    }
    setIsLoading(false)
  }

  const fetchOrgDataAndUpdate = async () => {
    setIsLoading(true)
    try {
      const data = await fetchOrganizationData(jwtToken)
      setUserData(data)
      if (data?.address?.state === 'Haryana') {
        setSameState(true)
      }
      if (data && data?.step === 1) {
        showWarning(
          'Profile Incomplete...',
          'Your Profile is not completed, please complete your profile first'
        )
        navigate('/banking/borrowerDashboard/2')
      } else if (data && data?.step === 2 && !data?.payment) {
        navigate('/banking/purchaseSummary')
      } else if (data && data?.step === 3 && data?.payment) {
        navigate('/banking/borrowerDashboard/profile')
      }
    } catch (error) {
      console.error('Error retrieving organization data:', error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const decodedData = decodeJwtToken(jwtToken)
    setUserId(decodedData?._id)
    if (decodedData?.role === 'user') {
      fetchDataAndUpdateUserData()
    }
    if (decodedData?.role === 'entity') {
      fetchOrgDataAndUpdate()
    }
  }, [])

  useEffect(() => {
    if (userData?.payment === 'true' && userData?.step === 3) {
      const isPaymentDone = userData.payment === 'true'
      if (isPaymentDone) {
        navigate('/banking/borrowerDashboard/dashboard')
      }
    }
  }, [userData])

  const proceedToPay = async () => {
    setPayClicked(true)
    try {
      const data = await ProceedToPay(userData, userId)
      if (data?.instrumentResponse?.redirectInfo?.url) {
        window.location.href = data.instrumentResponse.redirectInfo.url
      }
    } catch (error) {
      console.error('Error retrieving user data:', error)
    }
    setPayClicked(false)
  }

  return (
    <>
      <div className='bg-bgclr8'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='container mx-auto'>
            <div className='lg:mx-20 mx-5 pb-16 md:pt-10 pt-28'>
              <div>
                <h1 className='lg:text-[36px] text-[30px] text-bgclr font-semibold underline'>
                  Order Summary
                </h1>
              </div>
              <div className='lg:grid lg:grid-cols-2 lg:mt-10 mt-2 gap-14'>
                <div className='lg:mt-5'>
                  <div className='lg:grid lg:grid-cols-5 text-bgclr'>
                    <h2 className='col-span-3 lg:text-[26px] text-[24px] font-semibold'>
                      Banking Platform One Time Onboard Fees
                    </h2>
                    <p className='col-span-2 flex justify-end text-[24px]'>
                      INR 1180
                    </p>
                  </div>
                  <div class='flex lg:mt-7 mt-4 bg-bgclr9 rounded-md'>
                    <div class='bg-bgclr text-txtclr rounded-l-md'>
                      <div
                        className='rotate-180 text-white text-[24px] py-7 ml-1 mr-1'
                        style={{ writingMode: 'vertical-rl' }}
                      >
                        Your Details
                      </div>
                    </div>
                    <div className='mt-7 ml-10 text-[18px]'>
                      <h3 className='font-semibold'>
                        {`${userData?.first_name || ''} ${
                          userData?.middle_name || ''
                        } ${userData?.last_name || ''}`.trim() ||
                          `${userData?.name || ''}`}
                      </h3>
                      <p>
                        <span className='font-semibold'>Email: </span>
                        {userData?.email}
                      </p>
                      <p>
                        <span className='font-semibold'>Phone No: </span>
                        {userData?.phone_number}
                      </p>
                      <p>
                        <span className='font-semibold'>State: </span>
                        {userData?.address?.state}
                      </p>
                      <p>
                        <span className='font-semibold'>Address: </span>
                        {`${
                          userData?.address?.permanent_address || ''
                        }`.trim() ||
                          `${userData?.address?.registered_address || ''}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='bg-bgclr9 border-2 lg:mt-0 mt-6 rounded-md'>
                  <div>
                    <div className='text-slate-500 p-5 text-[20px] font-semibold border-b'>
                      <h2>PRICE DETAILS</h2>
                    </div>
                    <div className='m-5 text-[18px] border-b-2 border-dashed font-semibold text-slate-700'>
                      <div className='flex justify-between mb-2'>
                        <h3>Price (1 item)</h3>
                        <p className='text-slate-500'>INR 1000</p>
                      </div>
                      {sameState && (
                        <div className='flex justify-between mb-2'>
                          <h3>CGST (9%)</h3>
                          <p className='text-slate-500'>INR 90</p>
                        </div>
                      )}
                      {sameState && (
                        <div className='flex justify-between mb-2'>
                          <h3>SGST (9%)</h3>
                          <p className='text-slate-500'>INR 90</p>
                        </div>
                      )}
                      {!sameState && (
                        <div className='flex justify-between mb-7'>
                          <h3>IGST (18%)</h3>
                          <p className='text-slate-500'>INR 180</p>
                        </div>
                      )}
                    </div>
                    <div className='flex justify-between mb-4 text-[24px] mx-5 font-semibold'>
                      <h3>Total Payable</h3>
                      <p>1180</p>
                    </div>
                    <div className='my-10 mx-10'>
                      <button
                        disabled={payClicked}
                        onClick={() => proceedToPay()}
                        className='bg-bgclr py-2 rounded-md w-full text-txtclr'
                      >
                        {payClicked ? (
                          <div className='flex justify-center items-center'>
                            <FaSpinner className='animate-spin text-3xl' />
                          </div>
                        ) : (
                          'Proceed to pay'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PurchaseSummary
